import React from "react";

import { Alert, AlertProps } from "@material-ui/lab";
import { classes } from "typestyle";

import { WithRequired } from "../../shared/utilityTypes";
import Button, { ButtonProps } from "../ui/Button";
import SvgIcon from "../ui/SvgIcon";
import { styles } from "./Alert.styles";

export type PulseAlertProps = WithRequired<
  Omit<AlertProps, "action">,
  "severity"
> & {
  action?: Omit<ButtonProps, "color" | "variant">;
};

const AlertActionButton = ({
  action,
  onClose,
}: {
  action?: PulseAlertProps["action"];
  onClose: (event: React.SyntheticEvent) => void;
}) => {
  if (action) {
    const { children, ...buttonProps } = action;
    return (
      <>
        <Button
          variant={"outlined"}
          color={"gray"}
          className={styles.actionButton}
          {...buttonProps}
        >
          {children}
        </Button>
        <SvgIcon
          className={styles.closeButton}
          src="/icons/close_icon.svg"
          onClick={onClose}
        />
      </>
    );
  }
  return null;
};

export default function PulseAlert({
  action,
  severity,
  onClose,
  ...props
}: PulseAlertProps) {
  const iconMapping = {
    error: <SvgIcon className={styles.svgIcon} src="/icons/error_icon.svg" />,
    info: (
      <SvgIcon className={styles.svgIcon} src="/icons/informative_icon.svg" />
    ),
    success: (
      <SvgIcon className={styles.svgIcon} src="/icons/success_icon.svg" />
    ),
    warning: (
      <SvgIcon className={styles.svgIcon} src="/icons/warning_icon.svg" />
    ),
  };

  const handleClose = (event: React.SyntheticEvent) => {
    if (onClose) {
      onClose(event);
    }
  };

  return (
    <Alert
      className={classes(styles.root, styles[severity])}
      action={<AlertActionButton action={action} onClose={handleClose} />}
      variant="outlined"
      icon={iconMapping[severity]}
      {...props}
    >
      {props.children}
    </Alert>
  );
}
