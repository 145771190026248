import { primaryColors, utilityColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export const styles = stylesheet({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 1rem",
    borderRadius: "6px",
    marginBottom: "1rem",
    ...typography.SUBTITLE2,
    color: primaryColors.TEXT_ACTIVE,
    fontWeight: 600,
  },
  svgIcon: {
    width: "24px",
    height: "24px",
  },
  actionButton: {
    borderRadius: "8px",
  },
  closeButton: {
    margin: "6px",
    marginLeft: "20px",
    cursor: "pointer",
    width: "10px",
    height: "10px",
    color: "gray",
  },
  info: {
    backgroundColor: "#CFE6F4",
    borderColor: utilityColors.BLUE,
  },
  success: {
    backgroundColor: utilityColors.GREEN_OUTLINE_HOVER,
    borderColor: utilityColors.GREEN,
  },
  error: {
    backgroundColor: utilityColors.RED_OUTLINE_HOVER,
    borderColor: utilityColors.RED,
  },
  warning: {
    backgroundColor: "#F9ECD2",
    borderColor: utilityColors.YELLOW,
  },
});
