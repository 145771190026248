import React from "react";

import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
} from "@material-ui/core";
import { classes } from "typestyle";

import styles, {
  linkVariantStyles,
  normalVariantStyles,
  outlinedVariantStyles,
  textVariantStyles,
  transparentVariantStyles,
} from "./Button.styles";

// different variants on figma
// confirm - orange bg - white text
// confirm - purple bg - white text
// select - green bg - white text
// cancel - gray bg - black text
// delete - red bg - white text

export type ButtonColors =
  | "gray"
  | "green"
  | "purple"
  | "orange"
  | "red"
  | "orangeHover";

export type ButtonProps<
  D extends React.ElementType<any> = "button",
  P = {}
> = Omit<MuiButtonProps<D, P>, "variant" | "action" | "color"> & {
  isLoading?: boolean;
  ["data-cy"]?: string;
} & (
    | {
        variant: "normal";
        color?: ButtonColors;
      }
    | {
        variant: "transparent";
        // todo add support for other colors
        color?: Extract<ButtonColors, "gray" | "orange">;
      }
    | {
        variant: "outlined";
        // todo add support for other colors
        color?: Extract<
          ButtonColors,
          "orange" | "purple" | "green" | "red" | "gray"
        >;
      }
    | {
        variant: "link";
        // todo add support for other colors
        color?: Extract<ButtonColors, "orange" | "gray">;
      }
    | {
        variant: "text";
        // todo add support for other colors
        color?: Extract<ButtonColors, "orange" | "gray">;
      }
  );

function setVariant<D extends React.ElementType<any> = "button">(
  variant: ButtonProps<D>["variant"],
  color: ButtonColors
): { variant: MuiButtonProps["variant"]; styles: string } {
  switch (variant) {
    case "normal":
      return { variant: "contained", styles: normalVariantStyles[color] };
    case "transparent":
      return {
        variant: "text",
        styles: transparentVariantStyles[color as "gray" | "orange"],
      };
    case "outlined":
      return {
        variant: "outlined",
        styles: outlinedVariantStyles[color as "orange" | "gray"],
      };
    case "link":
      return {
        variant: "text",
        styles: linkVariantStyles[color as "orange"],
      };
    default:
      return {
        variant: "text",
        styles: textVariantStyles[color as "black" | "orange" | "gray"],
      };
  }
}

const Button = React.forwardRef<unknown, ButtonProps>(function Button<
  D extends React.ElementType<any> = "button"
>(
  {
    className,
    color = "orange",
    variant,
    isLoading,
    classes: buttonClasses,
    ...props
  }: ButtonProps<D>,
  ref: any
) {
  const variantConfig = setVariant(variant, color);

  let loadingProps: Partial<MuiButtonProps> = {};

  if (isLoading) {
    loadingProps = {
      endIcon: <CircularProgress classes={{ root: styles.loadingIcon }} />,
      children: "Loading",
      disabled: true,
      classes: {
        endIcon: styles.loadingIcon,
      },
    };
  }

  return (
    <MuiButton
      {...props}
      className={classes(
        variantConfig.styles,
        variant === "link" && linkVariantStyles.root,
        className
      )}
      classes={{
        ...buttonClasses,
        contained: classes(
          normalVariantStyles.noShadow,
          buttonClasses?.contained
        ),
        sizeSmall: classes(styles.small, buttonClasses?.sizeSmall),
        sizeLarge: classes(styles.large, buttonClasses?.sizeLarge),
        startIcon: classes(styles.startIcon, buttonClasses?.startIcon),
        endIcon: classes(
          styles.endIcon,
          isLoading && loadingProps.classes?.endIcon,
          buttonClasses?.endIcon
        ),
        root: classes(styles.root, buttonClasses?.root),
        disabled: "disabled",
      }}
      variant={variantConfig.variant}
      ref={ref}
      {...loadingProps}
    />
  );
});

export default Button;
