import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import history from "customHistory";
import Root from "Root";

import reportWebVitals from "./reportWebVitals";

console.log(`Version: ${process.env.REACT_APP_VERSION}`);

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      // @ts-expect-error
      new Integrations.BrowserTracing({
        shouldCreateSpanForRequest: () => true,
        tracePropagationTargets: [
          "localhost",
          /^\//,
          "api.preprod.pulsemarket.com",
          "api.qa.pulsemarket.com",
          "api.sandbox.pulsemarket.com",
          "api.live.pulsemarket.com",
        ],
        // @ts-expect-error
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Integrations.Breadcrumbs({ console: false }),
    ],

    // Configures the sample rate for error events, in the range of 0.0 to 1.0.
    // If set to 0.1 only 10% of error events will be sent. Events are picked randomly.
    sampleRate: 1.0,
    // A number between 0 and 1, controlling the percentage chance a given transaction will be sent to Sentry.
    tracesSampleRate: 0.1,
    // By default the SDK will try to read this value from the SENTRY_RELEASE environment variable
    // or window.SENTRY_RELEASE if available
    release: "pulse-web-ui@" + process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
